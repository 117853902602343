import { IMembersAreaWidgetPluginService } from '@wix/members-area-widget-plugin-lib/dist/types/types';

import { RootState } from '../store/root-reducer';
import { getActionHandlers } from '../store';
import { SettingsEventHandler } from '../components/Profile/controller-listeners';
import { FlowApi, SetProps, WixCodeApi } from './editor-flow';

type ActionHandlers = ReturnType<typeof getActionHandlers>;
type MemberHandlers = ActionHandlers['memberHandlers'];
type BlogPostsHandlers = ActionHandlers['blogPostsHandlers'];
type SiteHandlers = ActionHandlers['siteHandlers'];
type UIHandlers = ActionHandlers['uiHandlers'];

export type ControllerContext = {
  flowAPI: FlowApi;
  wixCodeApi: WixCodeApi;
  setProps: SetProps;
  widgetPluginService: IMembersAreaWidgetPluginService;
  settingsListener: SettingsEventHandler;
};

export interface ControllerProps {
  instance: string;
  member: RootState['member'];
  defaultBIParams: Object;
  site: RootState['site'];
  badges: RootState['badges'];
  blogPosts: RootState['blogPosts'];
  ui: RootState['ui'];
  appSettings: RootState['appSettings'];
  memberHandlers: MemberHandlers;
  blogPostsHandlers: BlogPostsHandlers;
  siteHandlers: SiteHandlers;
  uiHandlers: UIHandlers;
  isRendered: boolean;
  fitToContentHeight?: true;
}

export enum WarmupDataKey {
  InitialData = 'initialData',
}
