interface Section {
  appDefinitionId: string;
  sectionId: string;
  widgetId: string;
  memberId?: string;
  memberSlug?: string;
  tpaInnerRoute?: string;
}

export interface MembersAreaApi {
  navigateToSection: (section: Section) => Promise<void>;
}

export enum ProfileChangeEvent {
  BadgeAssigned = 'badge-assigned',
  MemberBlocked = 'member-blocked',
  PublicProfilePreviewChanged = 'public-profile-preview-changed',
}

type ProfileChangeEventPayload<T extends ProfileChangeEvent> = { event: T };

interface BadgesAssignedPayload {
  event: ProfileChangeEvent.BadgeAssigned;
  assignedBadgeIds: string[];
}

interface PublicProfilePreviewChangedPayload {
  event: ProfileChangeEvent.PublicProfilePreviewChanged;
  isPublicProfilePreview: boolean;
}

type ProfileChangePayload =
  | ProfileChangeEventPayload<ProfileChangeEvent.MemberBlocked>
  | BadgesAssignedPayload
  | PublicProfilePreviewChangedPayload;

type ProfileChangeCallback = (
  payload: ProfileChangePayload,
) => Promise<void> | void;

export interface ProfileCardAPI {
  registerToProfileChange?: (callback: ProfileChangeCallback) => void;
}
